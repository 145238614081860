import React from 'react';
import cn from 'classnames';
import { Link } from 'gatsby';
import './Buttons.scss';

const Buttons = ({ buttons, theme, styled = true }) => (
  <div className="buttons">
    {buttons.map((button) => {
      const {
        buttonLabel,
        buttonLink,
        buttonChildren,
      } = button;
      const { slug, url } = buttonLink;
      if (!buttonLink) return null;
      let to = slug ? `/${slug === 'home' ? '' : `${slug}`}` : null;
      const isAbsolute = new RegExp('^(//|[a-z]+:)', 'i');
      if (!isAbsolute.test(url)) {
        to = url;
      }
      if (to) {
        return (
          <Link
            key={to}
            to={to}
            className={cn('button', styled && `styled-button${theme ? `styled-button-${theme}` : ''}`)}
            aria-label={`Navigate to ${buttonLabel.text}`}
          >
            {buttonChildren || (
              <span>{buttonLabel.text}</span>
            )}
          </Link>
        );
      } else if (url) { // eslint-disable-line
        return (
          <a
            key={buttonLink.url}
            href={buttonLink.url}
            className={cn('button', styled && `styled-button styled-button-${theme}`)}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={`Navigate to ${buttonLabel.text}`}
          >
            {buttonChildren || (
              <span>{buttonLabel.text}</span>
            )}
          </a>
        );
      }
      return null;
    })}
  </div>
);

export default Buttons;
